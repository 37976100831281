import React from 'react'

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          {/* <div className="col-xs-12 col-md-6">
          <div className="about-text" id="news">
              <h2>News</h2>
          <div className="list-style">
                <div className="col-lg-8 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.News.map((d, i) => (
                          <li key={`${d}-${i}`}>
                            <b>{d.date}</b> {d.body}
                            </li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                </div>
                </div>
          </div> */}
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
